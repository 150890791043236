import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />
        <div className='container text-center' style={{padding:'50px 0'}}>
            <h1 className='title-404' style={{fontSize:'80px', color:'#d8152a'}}>404</h1>
            <h3>Sorry, Page Not Found</h3>
        </div>
    </Layout>
)

export default NotFoundPage
